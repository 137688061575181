import { useEffect } from "react";

const LinkedInTracking = () => {
  useEffect(() => {
    // LinkedIn Partner ID Script
    const linkedinScript1 = document.createElement("script");
    linkedinScript1.type = "text/javascript";
    linkedinScript1.innerHTML = `
      _linkedin_partner_id = "7030833";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `;
    document.head.appendChild(linkedinScript1);

    const linkedinScript2 = document.createElement("script");
    linkedinScript2.type = "text/javascript";
    linkedinScript2.innerHTML = `
      (function(l) {
        if (!l) {
          window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q = [];
        }
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";
        b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
      })(window.lintrk);
    `;
    document.head.appendChild(linkedinScript2);

    // Meta Pixel Code
    const metaPixelScript = document.createElement("script");
    metaPixelScript.type = "text/javascript";
    metaPixelScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '1087734788948719');
      fbq('track', 'PageView');
    `;
    document.head.appendChild(metaPixelScript);

    // Google Tag Manager Script
    const googleTagScript = document.createElement("script");
    googleTagScript.async = true;
    googleTagScript.src =
      "https://www.googletagmanager.com/gtag/js?id=G-K32FMEB9LM";
    document.head.appendChild(googleTagScript);

    const googleTagInlineScript = document.createElement("script");
    googleTagInlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-K32FMEB9LM');
    `;
    document.head.appendChild(googleTagInlineScript);

    // Cleanup function to remove scripts when component unmounts
    return () => {
      document.head.removeChild(linkedinScript1);
      document.head.removeChild(linkedinScript2);
      document.head.removeChild(metaPixelScript);
      document.head.removeChild(googleTagScript);
      document.head.removeChild(googleTagInlineScript);
    };
  }, []);

  return (
    <>
      {/* LinkedIn noscript */}
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=7030833&fmt=gif"
        />
      </noscript>

      {/* Meta Pixel noscript */}
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=1087734788948719&ev=PageView&noscript=1"
        />
      </noscript>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-M224P7R5"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
    </>
  );
};

export default LinkedInTracking;
