import { css } from 'styled-components';

// Theme
export const InformationTheme = {
    name: 'Information',
    Container: css`
        width: 100%;
        height: 100%;
        background: #ebeef1;
        overflow: auto;
    `,
    Card: css`
        color: #ffffff;
        margin-left: 12.5%;
        margin-right: 12.5%;
        text-align: center;
        @media (max-width: 1500px) {
            margin-left: auto;
            margin-right: auto;
            }
        @media (max-width: 576px) {
            margin-top: ${(props) => props.input === 'yes' ? '20%' : ''};
            width: -webkit-fill-available;
        }
    `,
    CardHeader: css``,
    StartPoint: css`
        background-color: #615a79;
        content: "";
        position: absolute;
        left: 0px;
        padding: 3px;
        border-radius: 0 50px 50px 0;
        height: 20px;
    `,
    CardBody: css`
        padding-top: 2rem;
        @media (max-width: 991px) {
            padding-top: 1rem;
        }
        @media (max-width: 576px) {
            padding-top: 0.625rem;
        }
    `,
    Question: css`
        font-weight: 600;
        margin: 0;
        font-size: 2.1rem;
        line-height: 2.5rem;
        white-space: pre-line;
        @media (max-width: 350px) {
            font-size: 0.875rem;
        }
        @media (max-width: 991px) {
            font-size: 1.5rem;
            line-height: 1.8rem;
        }
        @media (max-width: 767px) {
            font-size: 1.3rem;
            line-height: 1.6rem;
        }
        @media (max-width: 576px) {
            font-size: 1.4rem;
            line-height: 1.5rem;
        }
    `,
    QuestionAnswerWrapper: css`
        display: flex;
	    flex-wrap: wrap;
        margin-top: 1rem;
        @media (max-width: 767px) {
            margin-left: 6rem;
            margin-right: 6rem;
        }
        @media (max-width: 576px) {
            margin-left: 0rem;
            margin-right: 0rem;
        }
    `,
    FieldContainer: css`
        display: flex;
        text-align: justify;
        margin-bottom: 0.625rem;
        margin-right: 15px;
        margin-left: 15px;
        flex: 1 0 43%;
        box-sizing: border-box;
        @media (max-width: 500px) {
            margin-right: 2%;
            margin-left: 2%;
        }
    `,
    RadioLabel: css`
        display: flex;
        align-items: center;
        padding-left: 0;
        margin-left: 3rem;
        margin-right: 3rem;
        line-height: 2.5rem;
        @media (max-width: 500px) {
            padding-left: 0.938rem;
        }
        @media (max-width: 350px) {
            padding-left: 0.813rem;
        }
        @media (max-width: 576px) {
            margin-left: 1rem;
            margin-right: 1rem;
        }
    `,
    RadioButton: css`
        accent-color: #0A1F44;
        width: 1.5rem;
        height: 1.3rem;
        @media (max-width: 991px) {
            width: 1.2rem;
            height: 1.1rem;
        }
    `,
    RadioText: css`
        margin-left: 0.625rem;
        font-size: 1.6rem;
        @media (max-width: 350px) {
            margin-left: 0.5rem;
            font-size: 0.875rem;
        }
        @media (max-width: 991px) {
            font-size: 1.3rem;
        }
    `,
    AnswerWrapper: css`
        margin-left: 1rem;
        flex: 1;
    `,
    AnswerField: css`
        width: -webkit-fill-available;
        display: block;
        padding: 0.25rem 0.75rem;
        font-size: 0.9375rem;
        line-height: 1.6;
        color: #6b6f80;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e3e4e9;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border-radius: 5px;
        &:focus {
            border-color: #615a79;
            color: #1a1630;
            background-color: #fff;
            outline: 0;
            box-shadow: none;
        }
    `,
    Options: css`
        display: flex;
        padding: 5px 0;
        justify-content: center;
    `,
    Title: css`
        display: flex;
        font-size: 1.6rem;
        @media(max-width: 767px) {
            font-size: 1.3rem;
        }
    `,
    ButtonWrapper: css`
        display: flex;
        justify-content: center;
        margin-top: 2.5rem;
        margin-bottom: 1rem;
        gap: 1rem;
        @media (max-width: 991px) {
            margin-top: 1rem;
        }
    `,
    Button: css`
        cursor: pointer;
        display: block;
        color: ${(props) => props.theme.txtButton};
        background-color: ${(props) => props.theme.bgButton};
        border-color: ${(props) => props.theme.bgButton};
        box-shadow: none;
        border: 1px solid transparent;
        padding: 0.2rem 1.5rem;
        font-size: 1.5rem;
        line-height: normal;
        border-radius: 0.2rem;
        @media (max-width: 991px) {
            font-size: 1.2rem;
        }
        @media (max-width: 767px) {
            font-size: 1.2rem;
        }
        @media (max-width: 576px) {
            font-size: 1.2rem;
        }
        @media (max-width: 467px) {
            font-size: 1.2rem;
        }
    `,
}